import React, { useState, useEffect } from 'react';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import MediSupportHub from './Pages/MediSupportHub';
import GPLocator from './Pages/GPLocator';
import Event from './Pages/Event'
import Insight from './Pages/Insight';
import SymptomChecklist from './Pages/SymptomChecklist'
import PasswordModal from './Components/PasswordModal'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Loader from './Components/Loader'
import BackToTop from './Components/BackToTop';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  // loading function
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); 
        // Simulate an API request and return data after 2 seconds
        const response = await new Promise((resolve) => {
          setTimeout(() => resolve({ data: 'Some data from API' }), 2000);
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // loading finish
      }
    };

    fetchData();
  }, []);
// if loading, show loader
  if (loading) {
    return <Loader />;
  }

  // handle password
  const handleAuthenticationSuccess = () => {
    setIsAuthenticated(true);
  };
    
  return (
    <>
      {isAuthenticated ? (
        <>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mediSupport-hub" element={<MediSupportHub />} />
            <Route path="/gp-locator" element={<GPLocator />} />
            <Route path="/symptom-checklist" element={<SymptomChecklist />} />
            <Route path="/insight" element={<Insight />} />
            <Route path="/event" element={<Event />} /> 
            {/*<Route path="*" element={<Navigate to="/" />} />*/}
          
          </Routes>
          <Footer />
          <BackToTop />
        </>
      ) : (
        <PasswordModal onAuthenticated={handleAuthenticationSuccess} />
      )}
    </>
  );
}

export default App;