/* global google */
import React, { useEffect, useRef, useState } from 'react';
import Papa from 'papaparse';
import Plot from 'react-plotly.js'; // Import Plotly for visualization
import './GPLocator.css';
import { BiCurrentLocation } from 'react-icons/bi';
import NavigationButtons from '../Components/NavigationButtons';
import axios from 'axios';

const GPLocator = () => {
  const mapRef = useRef(null);
  const inputRef = useRef(null);
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMapVisible, setIsMapVisible] = useState(true);
  const [userPosition, setUserPosition] = useState(null);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const infoWindowRef = useRef(null); // Ref to manage the info window instance
  const [medicareData, setMedicareData] = useState([]); // State for Medicare data
  const [languageData, setLanguageData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [topSuburbs, setTopSuburbs] = useState([]);

  // Initialize map
  useEffect(() => {
    const initializeMap = (position) => {
      if (mapRef.current && !map) {
        const mapInstance = new google.maps.Map(mapRef.current, {
          center: position,
          zoom: 12,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false, 
        });
        setMap(mapInstance);
        setUserPosition(position);

        // Create a single InfoWindow instance
        infoWindowRef.current = new google.maps.InfoWindow();
      }
    };

    const handleLocationError = (error) => {
      console.error('Error fetching location:', error);
      initializeMap({ lat: -37.8136, lng: 144.9631 });
    };

    if (isMapVisible) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            initializeMap(userLocation);
          },
          handleLocationError
        );
      } else {
        handleLocationError(new Error('Geolocation not supported'));
      }
    } else {
      setMap(null);
    }
  }, [isMapVisible, map]);

  // Fetch GP locations from the backend API instead of CSV
  useEffect(() => {
    if (map) {
      axios.get('https://mwh-f3fmcndqbydagfdp.australiaeast-01.azurewebsites.net/api/gp_locations/')
        .then((response) => {
          const cleanedLocations = response.data.map(location => ({
            ...location,
            lat: parseFloat(location.lat),  // Ensure lat is a number
            long: parseFloat(location.long) // Ensure long is a number
          }));
          setLocations(cleanedLocations); // Store location data
        })
        .catch((error) => {
          console.error('Error fetching GP locations:', error);
        });
    }
  }, [map]);

  // Load Medicare data from CSV
  useEffect(() => {
    if (map) {
      Papa.parse('/cleaned_medicare_subsidy.csv', {
        download: true,
        header: true,
        complete: (results) => {
          const filteredMedicare = results.data.filter(item =>
            !['Chronic Disease Management Plan', "Mental Health", "Multidisciplinary Case Conference", 'Prolonged - Imminent danger of death'].includes(item.Service)
          ); 

          const coverage = filteredMedicare.reduce((acc, curr) => {
            const service = curr.Service;
            if (!acc[service]) {
              acc[service] = { totalFees: 0, totalBenefits: 0, count: 0 };
            }
            acc[service].totalFees += parseFloat(curr['Total provider fees ($)']) || 0;
            acc[service].totalBenefits += parseFloat(curr['Total Medicare benefits paid ($)']) || 0;
            acc[service].count += parseInt(curr['No. of services']) || 0;
            return acc;
          }, {});

          const medicareCoverage = Object.entries(coverage)
            .map(([service, values]) => ({
              Service: service,
              AverageServiceFees: values.totalFees / values.count,
              AverageMedicareBenefits: values.totalBenefits / values.count,
            }))
            .filter(item => item.Service && !isNaN(item.AverageServiceFees) && !isNaN(item.AverageMedicareBenefits)); // Filter out undefined and NaN values

          setMedicareData(medicareCoverage);
        },
        error: (error) => {
          console.error('Error loading Medicare data:', error);
        },
      });
    }
  }, [map]);

  // Initialize Autocomplete
  useEffect(() => {
    if (map && !autocomplete) {
      const input = document.getElementById('autocomplete-input');
      const melbourneBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-37.9236, 144.7631), 
        new google.maps.LatLng(-37.7036, 145.1631)
      );

      const autocompleteInstance = new google.maps.places.Autocomplete(input, {
        bounds: melbourneBounds,
        strictBounds: true,
        componentRestrictions: { country: 'au' },
        types: ['geocode'],
      });

      autocompleteInstance.setFields(['geometry', 'name', 'formatted_address']);
      autocompleteInstance.addListener('place_changed', () => {
        const place = autocompleteInstance.getPlace();

        if (place.geometry) {
          const location = place.geometry.location;
          setSearchQuery(place.formatted_address);
          map.panTo(location);
          setUserPosition({ lat: location.lat(), lng: location.lng() });
          showNearestMarkers({ lat: location.lat(), lng: location.lng() });
        }
      });

      setAutocomplete(autocompleteInstance);
    }
  }, [map]);

  // Load language data for suburb language info
  useEffect(() => {
    Papa.parse('/region_language.csv', {
      download: true,
      header: true,
      complete: (results) => {
        setLanguageData(results.data);
      },
    });
  }, []);

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    updateTopSuburbs(language);
  };

  const updateTopSuburbs = (language) => {
    const filteredData = languageData.filter(item => item.language_name === language);
    const sortedSuburbs = filteredData.sort((a, b) => b.total_speakers - a.total_speakers).slice(0, 5);
    setTopSuburbs(sortedSuburbs);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setShowClearIcon(false);
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
    setShowClearIcon(e.target.value.length > 0);
  };

  // Clear markers from the map
  const clearMarkers = () => {
    markers.forEach((marker) => marker.setMap(null));
    setMarkers([]);
  };

  // Add markers to the map
  const addMarkersToMap = (locations, position) => {
    if (!map) return;

    clearMarkers();
    const distances = locations
      .map((location) => {
        const { lat, long, org_name, address, opening_hours } = location;
        if (!lat || !long) return null;

        const locationPosition = new google.maps.LatLng(parseFloat(lat), parseFloat(long));
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          locationPosition,
          new google.maps.LatLng(position.lat, position.lng)
        );
        return { location, distance };
      })
      .filter(Boolean);

    distances.sort((a, b) => a.distance - b.distance);
    const nearestLocations = distances.slice(0, 5);

    const newMarkers = nearestLocations.map(({ location }) => {
      const { lat, long, org_name, address, opening_hours } = location;
      const markerPosition = { lat: parseFloat(lat), lng: parseFloat(long) };

      const marker = new google.maps.Marker({
        position: markerPosition,
        map: map,
        title: org_name,
      });

      const infoWindowContent = `
        <div>
          <strong>${org_name}</strong><br />
          <span>${address}</span><br />
          ${opening_hours ? `<div>${opening_hours}</div>` : '<em>No opening hours available</em>'}
        </div>
      `;

      marker.addListener('click', () => {
        infoWindowRef.current.setContent(infoWindowContent);
        infoWindowRef.current.open(map, marker);
      });

      return marker;
    });

    setMarkers(newMarkers);
  };

  // Show nearest markers based on user position
  const showNearestMarkers = (position) => {
    if (!position || locations.length === 0) return;
    addMarkersToMap(locations, position);
  };

  // Handle search using address
  const handleSearch = () => {
    if (!map) return;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: searchQuery }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const location = results[0].geometry.location;
        map.panTo(location);
        map.setZoom(15);
        setUserPosition({ lat: location.lat(), lng: location.lng() });
        showNearestMarkers({ lat: location.lat(), lng: location.lng() });
      } else {
        alert('Search was unsuccessful, please try again.');
      }
    });
  };

  // Handle current location
  const handleCurrentLocation = () => {
    if (!map || !navigator.geolocation) return;
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
        map.panTo(userLocation);
        setUserPosition(userLocation);
        showNearestMarkers(userLocation);
      },
      (error) => {
        alert('Unable to retrieve current location.');
        console.error('Error fetching current location:', error);
      }
    );
  };

  // Prepare data for Plotly visualization
  const services = medicareData.map(item => item.Service);
  const averageFees = medicareData.map(item => item.AverageServiceFees);
  const averageBenefits = medicareData.map(item => item.AverageMedicareBenefits);
  const outOfPocket = averageFees.map((fee, index) => fee - averageBenefits[index]);

  const serviceOrder = [
    'Telehealth (patient-end support)', 'Short (Level A)', 'Standard (Level B)',
    'Long (Level C)', 'Prolonged (Level D)', 'After-hours (urgent)',
    'After-hours (non-urgent)', 'Health Assessment', 'Pregnancy Support Counselling',
    'Other GP Services',
  ];

  return (
    <div className="gplocator container">
      <div className="gplocator-header">
        <h1>Find A Doctor</h1>
      </div>
      <div className="introduction">
        <p>Enter an address or use your current location to discover local healthcare services with opening hours info.</p>
        <MapInstructions />
        <div className='language-suburb-container'>
          <div className='language-select'>
            <div className="title-dropdown">
              <p id='selectLanguage'>Top Suburbs Speaking</p>
              <select className="language-selection" value={selectedLanguage} onChange={handleLanguageChange}>
                <option value="" disabled>Language</option>
                <option value="Arabic">Arabic</option>
                <option value="Pashto">Pashto</option>
                <option value="Burmese, Myanmar and Related Languages">Burmese</option>
                <option value="Urdu">Urdu</option>
              </select>
            </div>
            <ol className='suburbs-list'>
              {topSuburbs.map((suburb, index) => (
                <li key={index}>{suburb.sa3_name}</li>
              ))}
            </ol>
            <p className='note'>Note: There is a higher chance of finding a GP who speaks the same language in these suburbs, but there is no guarantee.</p>
          </div>
        </div>
      </div>

      <div className="controls">
        <div className="search-bar-container">
          <input
            id="autocomplete-input"
            ref={inputRef}
            type="text"
            placeholder="Enter location"
            value={searchQuery}
            onChange={handleInputChange}
          />
          {showClearIcon && <p className="clear-btn" onClick={handleClearSearch}>x</p>}
        </div>
        <button onClick={handleSearch}>Search</button>
        <button onClick={handleCurrentLocation}>Use Current Location</button>
      </div>

      {isMapVisible && <div ref={mapRef} className="map" />}

      <div className='gp-locator-fees-info'>
        <h1>Out-of-Pocket Costs</h1>
        <OutOfPocketInstructions />
        {medicareData.length > 0 && (
          <Plot
            data={[
              {
                y: services,
                x: averageBenefits,
                name: 'Medicare Coverage',
                type: 'bar',
                orientation: 'h',
                marker: { color: '#add8e6' },
                hovertemplate: 'Service: %{y}<br>Medicare Coverage: $%{x:.2f}',
              },
              {
                y: services,
                x: outOfPocket,
                name: 'Out-of-Pocket',
                type: 'bar',
                orientation: 'h',
                marker: { color: '#a52a2a' },
                hovertemplate: 'Service: %{y}<br>Out-of-Pocket: $%{x:.2f}',
              },
            ]}
            layout={{
              barmode: 'stack',
              title: 'Average Service Fees',
              xaxis: { title: 'Medical Fee ($)' },
              yaxis: {
                title: 'Service',
                automargin: true,
                categoryorder: 'array',
                categoryarray: serviceOrder,
              },
              height: 800,
              width: 1200,
              margin: { t: 80, b: 80, l: 100, r: 40 },
            }}
          />
        )}

        <table className="cost-table">
          <thead>
            <tr>
              <th style={{ 'textAlign': 'left' }}>Service</th>
              <th>Average Cost ($)</th>
              <th>Medicare Coverage ($)</th>
              <th>Out-of-Pocket Cost ($)</th>
            </tr>
          </thead>
          <tbody>
            {serviceOrder.map((service) => {
              const index = services.indexOf(service);
              const averageFee = averageFees[index];
              const averageBenefit = averageBenefits[index];
              const outOfPocketCost = outOfPocket[index];

              // filter undefined and NaN 
              if (averageFee === undefined || averageBenefit === undefined || isNaN(averageFee) || isNaN(averageBenefit)) {
                return null;
              }

              return (
                <tr key={index}>
                  <td style={{ textAlign: 'left' }}>{service}</td>
                  <td>{averageFee.toFixed(2)}</td>
                  <td style={{ 'color': '#82bccf' }}>{averageBenefit.toFixed(2)}</td>
                  <td style={{ color: '#a52a2a' }}>{outOfPocketCost.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <GPServicesInfo />
      <NavigationButtons />
    </div>
  );
};

export default GPLocator;

const MapInstructions = () => {
  return (
    <div className="instructions-container">
      <ol>
        <li>
          <strong>1. Allow Device to Access Current Location 📍</strong><br />
          Ensure your device <strong>allows access</strong> to your current location to find the nearest clinics.
        </li>
        <li>
          <strong>2. Explore Suburbs for Your Language 📢</strong><br />
          Select your language at below section to identify the <strong>top 5 suburbs</strong> where the language is most spoken.
        </li>
        <li>
          <strong>3. Enter Address or Postcode 🏠</strong><br />
          Type your address or postcode in the search bar and click <strong>Search</strong> to find clinics nearby.
        </li>
        <li>
          <strong>4. Use Current Location <BiCurrentLocation /></strong><br />
          Press the <strong>Use Current Location</strong> button to quickly locate clinics closest to your current position.
        </li>
        <li>
          <strong>5. View Clinic Details 🏥</strong><br />
          After clicking <strong>Search</strong>, markers will appear on the map. Click on any <strong>Marker</strong> to view the clinic’s details.
        </li>
        <li>
          <strong>6. Get Costs Insights 💡</strong><br />
          Scroll down to explore the <strong>average out-of-pocket costs</strong> for specific medical services.
        </li>
      </ol>
    </div>
  );
};

const OutOfPocketInstructions = () => {
  return (
    <div className="OutOfPocket">
      <p>This chart visualizes the <strong>average provider fees</strong> and <strong>Medicare coverage</strong> for various medical services.</p>
      <ul>
        <li><strong style={{ color: '#82bccf' }}>Medicare coverage</strong>: the portion of your medical service costs that Medicare covers.</li>
        <li><strong style={{ color: '#a52a2a' }}>Out-of-pocket cost</strong>: the difference between what your doctor charges for a medical service and what Medicare pays.</li>
      </ul>
      <p>In short, the <strong style={{ color: '#a52a2a' }}>out-of-pocket cost</strong> is the amount you ultimately have to pay for a medical service.</p>
    </div>
  );
};

const GPServicesInfo = () => {
  return (
    <div className='GPServicesInfo'>
      <h3>GP Consultation Types</h3>
      <div className="instructions-container">
        <ol>
          <li><strong>Short (Level A)</strong>: a brief medical appointment with a general practitioner (GP), typically lasting less than 6 minutes</li>
          <li><strong>Standard (Level B)</strong>: a consultation lasting less than 20 minutes for cases that are not obvious or straightforward</li>
          <li><strong>Long (Level C)</strong>: a consultation lasting at least 20 minutes and less than 40 minutes</li>
          <li><strong>Extended (Level D)</strong>: a consultation lasting at least 40 minutes</li>
          <li className='note'>If your GP is closed and you need <strong>urgent</strong> health advice after-hours, call the helpline free* on 1800 022 222 for information on where to go to get help in your area.</li>
        </ol>
      </div>
    </div>
  );
};
