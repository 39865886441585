import React, { useEffect, useState, useRef } from 'react';
import Chart from 'chart.js/auto';  // Using Chart.js to generate charts
import axios from 'axios';
import './Insight.css'; 
import { Link } from 'react-router-dom';
import NavigationButtons from '../Components/NavigationButtons';

// Helper function to fetch data from the Django backend API
const fetchData = async () => {
  try {
    const response = await axios.get('https://mwh-f3fmcndqbydagfdp.australiaeast-01.azurewebsites.net/api/health_data/');  // Ensure Django API URL is correct
    return response.data;  // Assuming the API returns data in JSON format
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
};

const Insight = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('AFG');
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('Y30T34');
  const [topCauses, setTopCauses] = useState([]);
  
  // References for chart canvases
  const barChartRef = useRef(null);
  const lineChartRef = useRef(null);
  let barChartInstance = useRef(null);
  let lineChartInstance = useRef(null);

  useEffect(() => {
    fetchData().then((resultData) => {
      setData(resultData);
      updateCharts(resultData);
    });
  }, []);

  // Update charts when country or age group changes
  useEffect(() => {
    updateCharts(data);
  }, [selectedCountry, selectedAgeGroup, data]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleAgeGroupChange = (event) => {
    setSelectedAgeGroup(event.target.value);
  };

  // Calculate the average DALY rate by cause
  const avgDalyRateByCause = (data) => {
    const result = {};
    data.forEach((item) => {
      const key = item.Cause;
      const dalyRate = parseFloat(item['DALY rate per 100 000 population']) || 0;  // Convert to number type
      if (!result[key]) result[key] = [];
      result[key].push(dalyRate);
    });
    return Object.entries(result).map(([key, values]) => ({
      Key: key,
      Average: values.reduce((a, b) => a + b, 0) / values.length,
    }));
  };

  // Calculate the average DALY rate by year and cause
  const avgDalyRateByYear = (data) => {
    const result = {};
    data.forEach((item) => {
      const key = `${item.Year} - ${item.Cause}`;
      const dalyRate = parseFloat(item['DALY rate per 100 000 population']) || 0;  // Convert to number type
      if (!result[key]) result[key] = [];
      result[key].push(dalyRate);
    });
    return Object.entries(result).map(([key, values]) => ({
      Year: key.split(' - ')[0],
      Cause: key.split(' - ')[1],
      Average: values.reduce((a, b) => a + b, 0) / values.length,
    }));
  };

  // Update charts
  const updateCharts = (data) => {
    const filteredData = data.filter((item) => item.ISO3 === selectedCountry && item['Age Group'] === selectedAgeGroup);
    setFilteredData(filteredData);

    const barData = avgDalyRateByCause(filteredData);
    
    // Group data by year and cause for the line chart
    const lineData = filteredData.reduce((acc, curr) => {
      const year = curr.Year;
      const cause = curr.Cause;
      const dalyRate = parseFloat(curr['DALY rate per 100 000 population']) || 0;  // Ensure it's a number type
      if (!acc[cause]) {
        acc[cause] = [];
      }
      acc[cause].push({ year, dalyRate });
      return acc;
    }, {});

    // Destroy existing barChart instance if present
    if (barChartInstance.current) {
      barChartInstance.current.destroy();
    }

    // Create a new bar chart instance
    barChartInstance.current = new Chart(barChartRef.current, {
      type: 'bar',
      data: {
        labels: barData.map((item) => item.Key),
        datasets: [
          {
            label: 'Average DALY Rate',
            data: barData.map((item) => item.Average),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y', // Horizontal bar chart
        plugins: {
          legend: {
            display: false // Hide legend
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'DALY Rate per 100,000 Population'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Causes'
            }
          }
        },
      },
    });

    // Destroy existing lineChart instance if present
    if (lineChartInstance.current) {
      lineChartInstance.current.destroy();
    }

    // Create datasets for each cause, with the DALY rate per year
    const datasets = Object.entries(lineData).map(([cause, data]) => ({
      label: cause,
      data: data.map((d) => d.dalyRate),
      borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
      fill: false,
    }));

    // Create a new line chart instance
    lineChartInstance.current = new Chart(lineChartRef.current, {
      type: 'line',
      data: {
        labels: [...new Set(data.map(item => item.Year))].sort((a, b) => a - b), // Years as X-axis labels
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.5, // Adjust aspect ratio
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'DALY Rate per 100,000 Population'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Year'
            }
          }
        },
      },
    });

    // Update top 3 causes of death
    const causes = {};
    filteredData.forEach(item => {
      const dalyRate = parseFloat(item['DALY rate per 100 000 population']) || 0;  // Convert to number
      if (!causes[item.Cause]) causes[item.Cause] = [];
      causes[item.Cause].push(dalyRate);
    });

    const averageCauses = Object.entries(causes)
      .map(([cause, values]) => ({
        Cause: cause,
        Average: values.reduce((a, b) => a + b, 0) / values.length,
      }))
      .sort((a, b) => b.Average - a.Average)
      .slice(0, 3);

    setTopCauses(averageCauses);
  };

  const countryNames = {
    AFG: 'Afghanistan',
    IRQ: 'Iraq',
    MMR: 'Myanmar',
    PAK: 'Pakistan',
    SYR: 'Syria',
  };

  const ageGroupNames = {
    Y30T34: '30-34',
    Y35T39: '35-39',
    Y40T44: '40-44',
    Y45T49: '45-49',
  };

  return (
    <div className="insight">
      <h1 className="centered-header">Top Causes of Deaths Among Top 5 Migrant Countries in Australia</h1>
      <p className="introduction">The DALY rate per 100,000 population for the top 5 migrant countries into Australia.</p>

      <div className="summary-container">
        <li><b>Disability-adjusted life year (DALY)</b>: A measure of healthy life lost, either through premature death or living with disability due to illness or injury.</li>
        <li>One <b>DALY</b> = one year of healthy life lost to disease and injury</li>
      </div>

      <h2>Average Annual Life Lost Per 100,000 Population in {countryNames[selectedCountry]} in Age Group {ageGroupNames[selectedAgeGroup]}</h2>
      <div className="chart-wrapper">
        <div className="chart-container">
          <canvas ref={barChartRef} id="bar-chart"></canvas>
        </div>

        <div className="top-causes">
          <div className="dropdown-container-country">
            <label style={{'marginRight': '10px'}}>Select a country:</label>
            <select value={selectedCountry} onChange={handleCountryChange}>
              <option value="AFG">Afghanistan</option>
              <option value="IRQ">Iraq</option>
              <option value="MMR">Myanmar</option>
              <option value="PAK">Pakistan</option>
              <option value="SYR">Syria</option>
            </select>
          </div>

          <div className="dropdown-container-age">
            <label style={{'marginRight': '10px'}}>Select an age group:</label>
            <select className="dropdown-container-age" value={selectedAgeGroup} onChange={handleAgeGroupChange}>
              <option value="Y30T34">30-34</option>
              <option value="Y35T39">35-39</option>
              <option value="Y40T44">40-44</option>
              <option value="Y45T49">45-49</option>
            </select>
          </div>  

          <h2 className="top-cause-desc">Top 3 Causes of Death in 2021</h2>
          <div>
            {topCauses.map((cause, index) => (
              <p key={index} className={`top-cause top-cause-${index + 1}`}>
                {cause.Cause}: {Math.round(cause.Average)}
              </p>
            ))}
          </div>
        </div>
      </div>

      <h2>Average Life Lost Rate per 100,000 Population in {countryNames[selectedCountry]} in Age Group {ageGroupNames[selectedAgeGroup]} (2017-2021)</h2>
      <div className="chart-container">
        <canvas ref={lineChartRef} id="line-chart"></canvas>
      </div>

      <div className="description-section">
        <h1>Screening, Tests and Scans: What To Do Next? </h1>
        <h3>What are Diagnostic Tests?</h3>
        <ul>
          <li><b>Pathology Tests</b></li>
          <ul>
            <li className='extrainfo'>Blood tests</li>
            <li className='extrainfo'>Urine tests</li>
            <li className='extrainfo'>Biopsies</li>
          </ul>
          <li><b>Imaging tests or scans</b></li>
          <ul>
            <li className='extrainfo'>X-rays</li>
            <li className='extrainfo'>CT scans</li>
            <li className='extrainfo'>MRI scans</li>
            <li className='extrainfo'>PEI scans</li>
            <li className='extrainfo'>Ultrasound scans</li>
          </ul>
        </ul>
        <h3>In Australia, common screening tests program are:</h3>
        <ul>
          <li><b>Breast Screen Australia Program:</b> provides a free mammogram every 2 years to women aged 40 years and over</li>
          <ul>
            <li className='extrainfo'>Call <strong>13 20 50</strong> for screening information or to make an appointment.</li>
          </ul>
          <li><b>National Cervical Screening Program:</b> provides a free cervical screening test every 5 years to women aged 25 to 74 years</li>
          <ul>
            <li className='extrainfo'>If you are unsure whether you are due for a Cervical Screening Test, you can check with your healthcare provider or contact the National Cancer Screening Register on <strong>1800 627 701</strong>.</li>
          </ul>
          <li><b>National Diabetes Services Scheme:</b> helps people with diabetes to understand and manage their life with diabetes</li>
          <ul>
            <li className='extrainfo'>Call the NDSS Helpline on <strong>1800 637 700</strong> for information about access to subsidised diabetes products and support.</li>
          </ul>
          <li><b>Heart Health Check:</b> a 20-minute check-up with your GP to assess your risk of having a heart attack or stroke</li>
          <ul>
            <li className='extrainfo'>Speak to your GP about a Heart Health Check if you do not already have heart disease.</li>
            <li className='extrainfo' style={{'font-weight':'bold'}}>Who needs a Heart Health Check?</li>
            <ul>
              <li className='extrainfo'>All people aged 45-79 years</li>
              <li className='extrainfo'>People with diabetes from 35 years</li>
              <li className='extrainfo'>First Nations people from 30 years</li>
            </ul>
          </ul>
        </ul>
        <h3>Does Medicare cover the costs?</h3>
        <p><b>Yes, it covers the cost of most pathology tests.</b> Visit <Link to="/gp-locator">GP Locator</Link> to arrange a consultation in your local area now.</p>
      </div>
      <NavigationButtons />
    </div>
  );
};

export default Insight;
