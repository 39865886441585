import React, { useState } from 'react';
import { symptomCheckerData } from './data'; 
import Symptoms from '../Pictures/symptoms.png'; 

const SymptomMultiSelect = ({ onNext, onBack, selectedBodyParts = [], onChange, formData }) => {
  const [selectedSymptoms, setSelectedSymptoms] = useState(formData || {});  // Use formData to retain selections
  const [errorMessage, setErrorMessage] = useState('');
  const [shake, setShake] = useState(false);

  const handleSymptomChange = (bodyPart, symptom) => {
    const updatedSymptoms = {
      ...selectedSymptoms,
      [bodyPart]: selectedSymptoms[bodyPart]?.includes(symptom)
        ? selectedSymptoms[bodyPart].filter((s) => s !== symptom)
        : [...(selectedSymptoms[bodyPart] || []), symptom],
    };

    const totalSelected = Object.values(updatedSymptoms).flat().length;
    if (totalSelected > 5) {
      setErrorMessage('You can only select up to 5 symptoms.');
      setShake(true); 
      setTimeout(() => setShake(false), 500);
    } else {
      setSelectedSymptoms(updatedSymptoms);
      setErrorMessage('');
      onChange(updatedSymptoms);  // Ensure formData is updated with the selected values
    }
  };

  const handleNext = () => {
    const allPartsSelected = selectedBodyParts.every((part) => selectedSymptoms[part]?.length > 0);
    if (!allPartsSelected) {
      setErrorMessage('Please select at least one symptom for each body part.');
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } else {
      onNext();  // Proceed to the next step
    }
  };

  return (
    <div className={`symptom-container ${shake ? 'shake' : ''}`}>
      <div className="symptom-content">
        <div className="symptom-text">
          <h2>Select Symptoms for Each Body Part</h2>
          <div className="section-header">
            <span className="section-icon">✔️</span>
            <span>Select at least one symptom for each part (up to 5 in total)</span>
          </div>

          {selectedBodyParts.map((part) => (
            <div key={part} className="question">
              <hr />
              <h3>Symptoms for {part}</h3>
              <div className="checkbox-group">
                {(symptomCheckerData[part] || []).map((symptom) => (
                  <button
                    key={symptom}
                    className={selectedSymptoms[part]?.includes(symptom) ? 'selected' : ''}
                    onClick={() => handleSymptomChange(part, symptom)}
                  >
                    {symptom}
                  </button>
                ))}
              </div>
            </div>
          ))}

          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <div className="button-container">
            <button className="btn back" onClick={onBack}>Back</button>
            <button className="btn next" onClick={handleNext}>Next</button>
          </div>
        </div>

        <div className="body-image">
          <img src={Symptoms} alt="Body Diagram" />
        </div>
      </div>
    </div>
  );
};

export default SymptomMultiSelect;



