import React, { useState, forwardRef,useImperativeHandle,useRef,useEffect}  from 'react';
import TermsOfService from '../Components/TermsOfService';
import HealthBackground from '../Components/HealthBackground';
import SymptomSelection from '../Components/SymptomsSelection';
import SymptomMultiSelect from '../Components/SymptomMultiSelect';
import ProgressBar from '../Components/ProgressBar';
import './SymptomChecklist.css'
import ReportSummary from '../Components/ReportSummary';
import { FaExclamationCircle } from 'react-icons/fa';
import NavigationButtons from '../Components/NavigationButtons';

const SymptomChecklist = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
      background: {},
      selectedBodyParts: [],
      selectedSymptoms: {},
    });

    // Reset the form and step to start fresh from TermsOfService
  const resetForm = () => {
    setFormData({
      background: {},
      selectedBodyParts: [],
      selectedSymptoms: {},
    });
    setCurrentStep(1);
  };

// Scroll to the top of the form whenever the step changes
const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

// Modified handleNext to include scrolling
const handleNext = () => {
  setCurrentStep(currentStep + 1);
  scrollToTop();  // Scroll to top after moving to the next step
};

// Modified handleBack to include scrolling
const handleBack = () => {
  setCurrentStep(currentStep - 1);
  scrollToTop();  // Scroll to top after moving to the previous step
};
  
    const handleFormDataChange = (section, data) => {
      setFormData({ ...formData, [section]: data });
    };

  const totalSteps = 4;
  
    return (
      <div className="symptomChecklist">
        <div className="symptom-header">
        <h1>Symptom Checklist</h1>
        </div>
        <div className="introduction">
        <p>The Symptom Checklist helps your doctor better understand your symptoms but cannot provide a diagnosis. Please download the checklist and take it to your GP. Completing all questions usually takes 2-3 minutes.</p>
        <SymptomChecklistInstructions />
        <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        </div>

        {/* Progress Bar */}
      

        {currentStep === 1 && <TermsOfService onNext={handleNext} />}
  
        {currentStep === 2 && (
          <HealthBackground
            onNext={handleNext}
            onBack={handleBack}
            onChange={(data) => handleFormDataChange('background', data)}
            formData={formData.background}
          />
        )}
  
        {currentStep === 3 && (
          <SymptomSelection
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            onChange={(data) => handleFormDataChange('selectedBodyParts', data.selectedBodyParts)}
          />
        )}
  
  {currentStep === 4 && (
        <SymptomMultiSelect
            onNext={handleNext}
            onBack={handleBack}
            selectedBodyParts={formData.selectedBodyParts}  // Passing the selected body parts
            formData={formData.selectedSymptoms}            // Pass previously selected symptoms
            onChange={(data) => handleFormDataChange('selectedSymptoms', data)} // Update symptoms in formData
        />
    )}
  
        {currentStep === 5 && (
          <ReportSummary
            formData={formData}     
            onBack={handleBack}     
            resetForm={resetForm} 
          />
        )}
        <NavigationButtons />
      </div>
      
    );
  };

  export default SymptomChecklist;
  const SymptomChecklistInstructions = () => {
    return (
      <div className="instructions-container">
        <ol>
          <li>
            <strong>1. Complete in Two Minutes ⏱️</strong><br />
            No typing required — simply <strong>select options</strong> to quickly complete the checklist.
          </li>
          <li>
            <strong>2. Select and Deselect 👆</strong><br />
            <strong>Click</strong> to choose an answer. <strong>Click again </strong>to undo your choice.
          </li>
          <li>
            <strong>3. Important Questions "!" 🚨</strong><br />
            Questions with an <FaExclamationCircle size="1em" color='blue' /> icon are important. Click to see why.
          </li>
          <li>
          <strong>4. Translate Your Report 🌍</strong><br />
          After completing the checklist, <strong>click the language selector</strong> to translate it into your preferred language, if required. Supported languages include Pashto, Arabic, Burmese, and Urdu</li>
          <li>
            <strong>5. Download Your Report 📄</strong><br />
            <strong>Download the report</strong> and bring along to your GP for clearer communication.
          </li>
        </ol>
      </div>
    );
  };
  