import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import './NavigationButtons.css'

// Define the order of pages
const pageOrder = [
  { path: '/', name: 'Home' },
  { path: '/mediSupport-hub', name: 'MediSupport Hub' },
  { path: '/insight', name: 'Trend' },
  { path: '/symptom-checklist', name: 'Symptoms Checklist' },
  { path: '/gp-locator', name: 'Find A Doctor' },
  { path: '/event', name: 'Event Planner' }
];

function NavigationButtons() {
  const navigate = useNavigate();  // For programmatic navigation
  const location = useLocation();  // To get the current path

  // Get the current page index in pageOrder
  const currentPageIndex = pageOrder.findIndex((page) => page.path === location.pathname);

  // Get the "next" and "previous" pages using the current index
  const nextPage = currentPageIndex >= 0 && currentPageIndex < pageOrder.length - 1
    ? pageOrder[currentPageIndex + 1]
    : null;
  const previousPage = currentPageIndex > 0
    ? pageOrder[currentPageIndex - 1]
    : null;

  // Scroll to top when navigating
  const scrollToTopAndNavigate = (path) => {
    navigate(path);  // Navigate to the specified path
    window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to the top smoothly
  };

  return (
    <div className="navigation-buttons">
      <div className="previous">
        {previousPage && (
          <button
            onClick={() => scrollToTopAndNavigate(previousPage.path)}
            className="navigation-link previous"
          >
            <FaArrowLeft />
            <span className="text" style={{ marginLeft: '5px' }}>
              Back: {previousPage.name}
            </span>
          </button>
        )}
      </div>

      <div className="next">
        {nextPage && (
          <button
            onClick={() => scrollToTopAndNavigate(nextPage.path)}
            className="navigation-link next"
          >
            <span className="text" style={{ marginRight: '5px' }}>
              Next: {nextPage.name}
            </span>
            <FaArrowRight />
          </button>
        )}
      </div>
    </div>
  );
}

export default NavigationButtons;