import React, { useEffect,useRef, useState } from 'react';
import './Event.css';
import { FaMapMarkerAlt,FaRegCalendarAlt,FaVideo } from 'react-icons/fa';
import { IoIosPeople } from "react-icons/io";
import { FiGlobe } from "react-icons/fi";
import { google } from "calendar-link";
import { MdOutlineSocialDistance } from "react-icons/md";
import Loader from '../Components/Loader'
import NavigationButtons from '../Components/NavigationButtons';

const Event = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [showClearIcon, setShowClearIcon] = useState(false); 
  const [selectedEvent, setSelectedEvent] = useState(null);
  const inputRef = useRef();

  // drag event slider 
  const [slidePanelPos, setSlidePanelPos] = useState({ x: 0, y: 0 });
  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 }); // Store the initial click position

  // Start dragging
  const startDragging = (e) => {
    setDragging(true);
    // Record the initial position where the user clicked
    setOffset({
      x: e.clientX - slidePanelPos.x,
      y: e.clientY - slidePanelPos.y,
    });
  };

  // Handle dragging
  const handleDragging = (e) => {
    if (dragging) {
      // Update the panel position based on mouse movement
      setSlidePanelPos({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  // Stop dragging
  const stopDragging = () => {
    setDragging(false);
  };
  
  // Sort filter state
  const [sortBy, setSortBy] = useState('date');

  const fetchEvents = async () => {
    const targetUrl = 'https://mwh-f3fmcndqbydagfdp.australiaeast-01.azurewebsites.net/api/fetch_events/';  // Django backend API path

    try {
      const response = await fetch(targetUrl, {
        mode: 'cors',
      });

      if (!response.ok) {
        throw new Error(`Request failed, status code: ${response.status}`);
      }

      const data = await response.json();
      setEvents(data.events);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchEvents();
  }, []);

// Google Places Autocomplete Initialization
useEffect(() => {
  if (window.google && window.google.maps) {
    const inputElement = inputRef.current; // Ensure the correct HTMLInputElement is passed
    if (inputElement) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputElement);
      const melbourneBounds = new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(-37.9236, 144.7631),
        new window.google.maps.LatLng(-37.7036, 145.1631)
      );
      autocomplete.setBounds(melbourneBounds);
      autocomplete.setFields(['geometry', 'formatted_address']); // Fetch address and geo
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          if (place.formatted_address.includes('Melbourne')) {
            setSearchQuery(place.formatted_address); // Update input with address
          } else {
            alert('Address is not in Melbourne. Please enter an address within Melbourne.');
            setSearchQuery('');
          }
        } else {
          alert('Cannot find this address, please enter again.');
          setSearchQuery('');
        }
      });
    }
  }
}, []);



// Handling input box changes
const handleInputChange = (e) => {
  setSearchQuery(e.target.value);
  setShowClearIcon(e.target.value.length > 0);
};

// Clear the search box
const handleClearSearch = () => {
  setSearchQuery('');
  setShowClearIcon(false);
  setUserLocation(null); // Clear the user location
};

// Get the user's current location
const handleCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      },
      (error) => {
        console.error('Error fetching location', error);
      }
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
};
  // Use Geocoding API to convert address to coordinates
  const geocodeAddress = async (address) => {
    const apiKey = 'AIzaSyCSn2laJSOlb0ywxq5ixZSJgnwGuMSYaAY'; // Replace with your Google Maps Geocoding API Key
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setUserLocation({
          lat: location.lat,
          lon: location.lng,
        });
        return true; // Success: Address or postcode found
      } else {
        return false; // Failure: No results
      }
    } catch (err) {
      console.error('Error fetching geocode:', err);
      return false; // Failure: Network or other error
    }
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // If there is input, perform the search
    if (searchQuery) {
      const success = await geocodeAddress(searchQuery);
      if (success) {
        // Only update search query if the geocode was successful
        setSearchQuery(searchQuery); // Continue with the query and show events
      } else {
        // If not successful, show error message and do not proceed
        console.log('Submit was unsuccessful, please try again.');
      }
    } else {
      console.log('Please enter a location or use current location.');
    }
  };

  // Calculate the distance between two points (using the Haversine formula)
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371000; // Earth's radius in meters
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance; // Return the distance between two points in meters
};

const addToGoogleCalendar = (event) => {
 // const startTime = new Date(event.datetime_start).toISOString().replace(/-|:|\.\d+/g, '');
  //const endTime = new Date(event.datetime_end).toISOString().replace(/-|:|\.\d+/g, '');

  const googleUrl = google({
    title: event.name || 'Event',
    description: event.description || '',
    start: event.datetime_start,
    end: event.datetime_end,
    location: event.address || 'Location not specified',
  });

  window.open(googleUrl, '_blank');
};

  // Filter and sort events
  const filteredEvents = events
    .filter(event => event.timezone === "Australia/Melbourne")
    .map(event => {
      if (userLocation && event.point) {
        const distance = calculateDistance(
          userLocation.lat,
          userLocation.lon,
          event.point.lat,
          event.point.lng
        );
        return { ...event, distance };
      }
      return { ...event, distance: Infinity };
    })
    .sort((a, b) => {
      if (sortBy === 'distance') return a.distance - b.distance;
      return new Date(a.datetime_start) - new Date(b.datetime_start);
    });

  // Error handling
  if (error) {
    return console.log(error);
  }

  // if loading, show loader
  if (loading) {
    return <Loader />;
  }

  const handleCardClick = (event) => {
    setSelectedEvent(event);
  };

  const closeSlider = () => {
    setSelectedEvent(null);
  };


  return (
    <div className="events-page">
      <div className="event-header">
        <h1>Upcoming Events in Melbourne</h1>
      </div>
      <div className="introduction">
       
        <EventInstructions />
       </div>
      

      <div className="controls">
      <div className="search-bar-container">
      <input
          id="autocomplete-input"
          ref={inputRef}
          type="text"
          placeholder="Enter location"
          value={searchQuery}
          onChange={handleInputChange}
        />
        {showClearIcon && <p className="clear-btn" onClick={handleClearSearch}>x</p>}
      </div>

      <button onClick={handleSubmit}>Submit</button>
      <button onClick={handleCurrentLocation}>Use Current Location</button>

      <button className="sort-button" onClick={() => setSortBy(sortBy === 'distance' ? 'date' : 'distance')}>
        Sort by: {sortBy === 'distance' ? 'Distance' : 'Date'}
      </button>
    </div>

      

      {/* Event Grid */}
      <div className="events-grid">
        {filteredEvents.map((event) => (
          <div key={event.id} className="event-card" onClick={() => handleCardClick(event)}>
            {event.images && event.images.images && event.images.images.length > 0 ? (
                <img 
                  src={event.images.images[0].transforms.transforms.slice(-1)[0].url} 
                  alt={event.name} 
                  style={{ width: '500px', height:'250px',borderRadius: '10px' }} // Increased width for better clarity
                />
              ) : (
                <p>No image available</p>
              )}
            <div className="event-card-content">
              <h3>{event.name}</h3>
              <p><FaRegCalendarAlt/> {event.datetime_start}</p>
              <p><FaRegCalendarAlt/>{event.datetime_end}</p>
              <p><FaMapMarkerAlt /> {event.address}</p>
              {userLocation && event.distance !== Infinity ? (
                <p><MdOutlineSocialDistance/> {(event.distance / 1000).toFixed(1)} km</p>
              ) : (
                <p><MdOutlineSocialDistance/> Enter your address to show distance</p>
              )}

            </div>
          </div>
        ))}
      </div>

      {/* Event Details Slider */}
      {selectedEvent && (
        <div
        className="event-slide-panel active"
        style={{ left: `${slidePanelPos.x}px`, top: `${slidePanelPos.y}px` }}
        onMouseDown={startDragging}
        onMouseMove={handleDragging}
        onMouseUp={stopDragging}
      >
          <span className="close-slider" onClick={closeSlider}>×</span>
          {selectedEvent.images && selectedEvent.images.images && selectedEvent.images.images.length > 0 ? (
                <img 
                  src={selectedEvent.images.images[0].transforms.transforms.slice(-1)[0].url} 
                  alt={selectedEvent.name} 
                  style={{ width: '600px', borderRadius: '10px' }} // Increased width for better clarity
                />
              ) : (
                <p>No image available</p>
              )}
          <h2>{selectedEvent.name}</h2>
          <p><FaRegCalendarAlt/> {selectedEvent.datetime_start}</p>
          <p><FaRegCalendarAlt/> {selectedEvent.datetime_end}</p>
          <p><FaMapMarkerAlt />  {selectedEvent.address}</p>
          <p><IoIosPeople/> {selectedEvent.restrictions}</p>
          <p>
            <FiGlobe />  <a href={selectedEvent.url} target="_blank" rel="noopener noreferrer">
              {selectedEvent.url}
             </a>
          </p>

          <p>
          <FaVideo /> {selectedEvent.video ? (
            <a href={selectedEvent.video} target="_blank" rel="noopener noreferrer">
              Watch Video
            </a>
          ) : (
            "None"
          )}
        </p>


          <p>
            Event Description:
            </p>
            <p>
            {selectedEvent.description}</p>
          
            <button className="google-calendar-btn" onClick={() => addToGoogleCalendar(selectedEvent)}>
              Add to Google Calendar
            </button>

        </div>
      )}
      <NavigationButtons />
      </div>
  );
};

export default Event;

const EventInstructions = () => {
  return (
    <div className="instructions-container">
      <ol>
      <li>
          <strong>1. Allow Device to Access Current Location and Use Current Location📍</strong><br />
          Ensure your device <strong>allows access </strong>to your current location to show your distance with events.
        </li>
        
        <li>
          <strong>2. Enter Address or Postcode 🏠</strong><br />
          Type your address or postcode in the search bar and click <strong>Submit</strong> toto show your distance with events.
        </li>
        <li>
          <strong>3. Click the Sort By Button 🔄</strong><br />
          Use the <strong>Sort By</strong> button to organize events by date or distance for easier browsing.
        </li>

        <li>
          <strong>4. Click on an Event 🎟️</strong><br />
          Select <strong>an event card</strong>, and detailed information about the event will appear on the right.
        </li>
        <li>
          <strong>5. Add Event to Google Calendar 🗓️</strong><br />
          Click the <strong>Add to Google Calendar</strong>button to save the event and set a reminder!
        </li>
      </ol>
    </div>
  );
};




