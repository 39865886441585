import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import { FaUserDoctor,FaClipboardList } from 'react-icons/fa6';
import { FaArrowRight } from 'react-icons/fa';
import { GiGiftOfKnowledge } from "react-icons/gi";
import { MdLanguage } from "react-icons/md";
import { IoBarChartSharp } from "react-icons/io5";
import doctor from '../Pictures/doctor.jpg'; 
import map from '../Pictures/map.png';
import language from '../Pictures/language.png';
import healthcareKnowledge from '../Pictures/healthcareKnowledge.png';
import barChart from '../Pictures/barChart.jpeg';
import symptomChecklist from '../Pictures/symptomChecklist.png';
import gpLocator from '../Pictures/gpLocator.png';
import { CgEventbrite } from "react-icons/cg";
import event from '../Pictures/event.jpeg';
import { TbMessageChatbotFilled } from "react-icons/tb";

import chatbot from '../Pictures/chatbot.png';

function Home() {
  const [selectedStep, setSelectedStep] = useState(1);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showGuide, setShowGuide] = useState(false);
  const [showServices, setShowServices] = useState(false);

  // back to up function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // scroll down to easy guide part
  const scrollToGuide = () => {
    const guideSection = document.querySelector('.easy-guide-container');
    guideSection.scrollIntoView({ behavior: 'smooth' });
  };
  
  // the setp guide details for easy guide part
  const stepsData = [
    {
      id: 1,
      icon: <MdLanguage/>,
      title: 'Step 1: Select Your Language',
      image: language,
      contentTitle:'Select Your Preferred Language',
      description: 'Select your native language at the top for easier navigation.',
    },
    {
      id: 2,
      icon: <GiGiftOfKnowledge />,
      title: 'Step 2: Learn About Healthcare',
      image: healthcareKnowledge,
      contentTitle:'Learn About Healthcare in MediSupport Hub',
      description: 'Understand more about Victorian healthcare info in MediSupport Hub, including benefits and steps to book a GP appointment.',
      link: '/mediSupport-hub',
    },
    {
      id: 3,
      icon: <IoBarChartSharp />,
      title: 'Step 3: Discover Women\'s Diseases ',
      image: barChart,
      contentTitle:"Women's Health Insights for 5 Migrant Countries",
      description: 'Gain insights on common women\'s diseases in the top 5 migrant countries in Australia and participate in the Australia common screening programs.',
      link: '/insight',
    },
    {
      id: 4,
      icon: <FaClipboardList />,
      title: 'Step 4: Translate Your Symptoms ',
      image: symptomChecklist,
      contentTitle:"Share Your Symptoms with Your Doctor",
      description: 'Answer quick health questions in 3 minutes and download in both English and your language to help your doctor identify the issue.',
      link: '/symptom-checklist',
    },
    {
      id: 5,
      icon: <FaUserDoctor />,
      title: 'Step 5: Find A Doctor',
      image: gpLocator,
      contentTitle:"Find the Nearest Clinics",
      description: 'Find and explore near clinics information and address. ',
      link: '/gp-locator',
    },
    {
      id: 6,
      icon: <CgEventbrite />,
      title: 'Step 6: Explore Ongoing Events',
      image: event,
      contentTitle:"Explore Events in Melbourne",
      description: 'Visit Event Planner and find some interesting events. Do not forget to add them to your calendar.',
      link: '/event',
    },
    {
      id: 7,
      icon: <TbMessageChatbotFilled />,
      title: 'Step 7: Ask Cindy Anything',
      image: chatbot,
      contentTitle:"Chat With Our Chatbot",
      description: 'Have quick questions about navigating our website? Don\'t worry! Our chatbot Cindy is here to guide you to the right place.',
    },
  ];

  // if the row > 300, show back to up button 
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    const showContent = () => {
      const guideSection = document.querySelector('.easy-guide-container');
      const servicesSection = document.querySelector('.recommended-services');

      const guidePosition = guideSection.getBoundingClientRect().top;
      const servicesPosition = servicesSection.getBoundingClientRect().top;

      // set guide section visible
      if (guidePosition <= window.innerHeight) {
        setShowGuide(true); 
      }
      // set services section visible
      if (servicesPosition <= window.innerHeight) {
        setShowServices(true); 
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', showContent);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', showContent);
    };
  }, []);

  return (
    <div className="home-page-container">
      <div className="home-overlay">
        <div className="home-cover">
          <h1>Empowering Migrant Women with Accessible Healthcare in Australia</h1>
          <p>Affordable care and multilingual support, helping migrant women overcome cultural and language barriers to better health and well-being.</p>

          <div className="home-button-container">
          <span onClick={scrollToGuide} className="learn-more-btn" style={{ cursor: 'pointer' }}>
            Learn More ↓
          </span>

          <Link to="/mediSupport-hub" className="get-start-btn" style={{ textDecoration: 'none' }}>
            Get Start
          </Link>
        </div>

        </div>
      </div>

      {/* Easy Guide Section */}
      <div className={`easy-guide-container ${showGuide ? 'fade-in' : ''}`}>
        <h2 className="guide-title">How We Support You</h2>
        <div className="guide-content">
          {/* Steps (Left side) */}
          <div className="steps-container">
            {stepsData.map((step) => (
              <div
                key={step.id}
                className={`step-button ${selectedStep === step.id ? 'selected' : ''}`}
                onClick={() => setSelectedStep(step.id)}
              >
                <span className="step-icon">{step.icon}</span>
                <span className="step-title">{step.title}</span>
                {selectedStep === step.id && <FaArrowRight  className="step-arrow" />}
              </div>
            ))}
          </div>

          {/* Content (Right side) */}
          <div className="guide-content-container">
            <img
              src={stepsData[selectedStep - 1].image}
              alt={stepsData[selectedStep - 1].contentTitle}
              className="guide-content-image"
            />
            <div className="guide-content-text">
              <h3>{stepsData[selectedStep - 1].contentTitle}</h3>
              <p>{stepsData[selectedStep - 1].description}</p>
              {stepsData[selectedStep - 1].id !== 1 && stepsData[selectedStep - 1].id !== 7 && (
                <Link to={stepsData[selectedStep - 1].link} className="learn-more">
                  Explore →
                </Link>
              )}

            </div>
          </div>
        </div>
      </div>
      <RecommendedServices />

      {showScrollTop && (
        <button onClick={scrollToTop} className="scroll-top-btn">
          ↑
        </button>
      )}
    </div>
  );
}

export default Home;

function RecommendedServices({ showServices }) {
  const servicesData = [
    {
      id: 1,
      title: 'Use Symptom checklist to Check your Symptoms ',
      description:
        'Worried about a language barrier or difficulty explaining your symptoms? Use our Symptom Checklist before going to your GP. Translate it into your preferred language, print it, and share with your doctor for an easier communication.',
      image: doctor,
      buttonText: 'Start Symptom Checklist',
      buttonLink: '/symptom-checklist',
    },
    {
      id: 2,
      title: 'Navigating Healthcare with the GP Locator',
      description:
        'Struggling to find affordable and nearest clinics? We can help you locate nearby clinics and provide you the average out-of-pocket costs for each GP services.',
      image: map,
      buttonText: 'Find A Doctor',
      buttonLink: '/gp-locator',
    },
  ];

  return (
    <div className={`recommended-services ${showServices ? 'fade-in' : ''}`}>
      <h2 className="recommended-section-title">Recommended Services</h2>
      {servicesData.map((service, index) => (
        <div key={service.id} className={`recommended-service-item ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
          <img src={service.image} alt={service.title} className="recommended-service-image" />
          <div className="recommended-service-content">
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <Link to={service.buttonLink} className="recommended-service-btn">
              {service.buttonText}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}

