import React, { useState, useRef } from 'react';
import jsPDF from 'jspdf'; 
import autoTable from 'jspdf-autotable'; 
import { toPng } from 'html-to-image'; 
import ClinicImage from '../Pictures/clinic.png'; 
import { useNavigate } from 'react-router-dom';

// Google Translate API function
const translateText = async (text, targetLanguage) => {
  const url = 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyBHztrNOuCxeKViU1c8r_mOaa4f4VC2j3E';

  const requestBody = {
    q: text,
    target: targetLanguage,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data?.data?.translations?.[0]?.translatedText || text; 
  } catch (error) {
    console.error('Error translating text:', error);
    return text; 
  }
};

const SymptomReport = ({ formData: initialFormData, onBack, resetForm }) => {
  const [formData, setFormData] = useState(initialFormData);
  const [selectedLanguage, setSelectedLanguage] = useState('en'); 
  const [translatedFormData, setTranslatedFormData] = useState(null); 
  const [isTranslating, setIsTranslating] = useState(false);
  const navigate = useNavigate();
  const reportRef = useRef(null); 

  const handleLanguageChange = async (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    setIsTranslating(true); 

    const translatedBackground = await Promise.all(
      Object.entries(formData.background).map(async ([question, answer]) => {
        const translatedQuestion = await translateText(question, language);
        const translatedAnswer = await translateText(answer, language);
        return { question: translatedQuestion, answer: translatedAnswer };
      })
    );

    const translatedSymptoms = await Promise.all(
      Object.entries(formData.selectedSymptoms).map(async ([bodyPart, symptoms]) => {
        const translatedBodyPart = await translateText(bodyPart, language);
        const translatedSymptomsList = await Promise.all(
          symptoms.map(async (symptom) => await translateText(symptom, language))
        );
        return { bodyPart: translatedBodyPart, symptoms: translatedSymptomsList };
      })
    );

    setTranslatedFormData({
      background: translatedBackground,
      selectedSymptoms: translatedSymptoms,
    });

    setIsTranslating(false); 
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    
    // Add the title
    doc.setFontSize(18);
    doc.text('Symptom Report', 105, 20, { align: 'center' });

    // Convert background data into table format
    const backgroundData = Object.entries(formData.background).map(([key, value]) => [key, value]);
    autoTable(doc, {
      head: [['Question (EN)', 'Answer (EN)']],
      body: backgroundData,
      startY: 30, // Start position
      theme: 'striped',
    });

    // Convert symptoms data into table format
    const symptomsData = Object.entries(formData.selectedSymptoms).map(([bodyPart, symptoms]) => [
      bodyPart,
      symptoms.join(', '),
    ]);
    autoTable(doc, {
      head: [['Body Part (EN)', 'Symptoms (EN)']],
      body: symptomsData,
      startY: doc.lastAutoTable.finalY + 10, // Add some space below the previous table
      theme: 'striped',
    });

    // Include translated data if available
    /*if (translatedFormData) {
      const translatedBackgroundData = translatedFormData.background.map(({ question, answer }) => [
        question,
        answer,
      ]);
      autoTable(doc, {
        head: [['Question (Translated)', 'Answer (Translated)']],
        body: translatedBackgroundData,
        startY: doc.lastAutoTable.finalY + 10, // Add some space below the previous table
        theme: 'striped',
      });

      const translatedSymptomsData = translatedFormData.selectedSymptoms.map(
        ({ bodyPart, symptoms }) => [bodyPart, symptoms.join(', ')]
      );
      autoTable(doc, {
        head: [['Body Part (Translated)', 'Symptoms (Translated)']],
        body: translatedSymptomsData,
        startY: doc.lastAutoTable.finalY + 10,
        theme: 'striped',
      });
    }*/

    // Save the PDF
    doc.save('Symptom_Report.pdf');
  };

  const handleDownloadImage = () => {
    if (reportRef.current) {
      toPng(reportRef.current, {
        backgroundColor: '#ffffff', 
        pixelRatio: 2,
        style: {
          transform: 'translateX(-500px)', 
          width: '1000px' 
        }
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'Symptom_Report.png';
          link.click();
        })
        .catch((error) => {
          console.error('Error generating image:', error);
        });
    }
  };
  const translations = {
    en: 'Translated',
    ps: 'ژباړل شوی',
    ar: 'مترجم',
    my: 'ဘာသာပြန်ထားသော',
    ur: 'ترجمہ شدہ',
  };
  
  return (
    <div className="symptom-container" ref={reportRef}>
      <div className="Report-title">
        <h2 className="centered-title">Symptom Report</h2>
      </div>
  
      <div className="language-selector">
        <label htmlFor="language" style={{'marginRight': '10px'}}>Select Language: </label>
        <select id="language" value={selectedLanguage} onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="ps">Pashto</option>
          <option value="ar">Arabic</option>
          <option value="my">Myanmar</option>
          <option value="ur">Urdu</option>
        </select>
      </div>
  
      <div className="symptom-clinic-container">
        <div className="clinic">
          <img src={ClinicImage} alt="Clinic" className="clinic-image" />
          <p>Take this summary report to a GP</p>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
            onClick={() => navigate('/gp-locator')}
          >
            Find a clinic
          </p>
        </div>
  
        <div className="symptom-report-content">
          <div className="report-section">
            <h3>Background (English)</h3>
            {Object.entries(formData.background || {}).map(([question, answer], index) => (
              <div key={index} className="report-item">
                <strong>{question}</strong>
                {/* Check if answer is 'None of Above', replace with 'None' */}
                <p>{answer === 'None of Above' ? 'None' : answer}</p>
                <hr />
              </div>
            ))}
          </div>
  
          {/* Translated Version */}
          {translatedFormData && (
            <div className="report-section">
              <h3>Background ({translations[selectedLanguage]})</h3>
              {translatedFormData.background?.map(({ question, answer }, index) => (
                <div key={index} className="report-item">
                  <strong>{question}</strong>
                  <p>{answer === 'None of Above' ? 'None' : answer}</p>
                  <hr />
                </div>
              ))}
            </div>
          )}
  
          <div className="report-section">
            <h3>Selected Symptoms (English)</h3>
            {Object.entries(formData.selectedSymptoms || {}).map(([bodyPart, symptoms], index) => (
              <div key={index} className="report-item">
                <strong>Symptoms for {bodyPart}</strong>
                {/* Check if any symptom is 'None of Above', replace with 'None' */}
                <p>{symptoms.map(symptom => symptom === 'None of Above' ? 'None' : symptom).join(', ')}</p>
                <hr />
              </div>
            ))}
          </div>
  
          {translatedFormData && (
            <div className="report-section">
              <h3>Selected Symptoms ({translations[selectedLanguage]})</h3>
              {translatedFormData.selectedSymptoms?.map(({ bodyPart, symptoms }, index) => (
                <div key={index} className="report-item">
                  <strong>Symptoms for {bodyPart}</strong>
                  <p>{symptoms.map(symptom => symptom === 'None of Above' ? 'None' : symptom).join(', ')}</p>
                  <hr />
                </div>
              ))}
            </div>
          )}
  
          <p style={{'color':'gray', 'fontSize':'13px'}}>Note: You can select a language above to download a report in both English and your language.</p>
          <div className="button-container">
            <button className="btn back" onClick={onBack}>Back</button>
            <button className="btn back" onClick={handleDownloadPDF}>Download English</button>
            <button className="btn back" onClick={handleDownloadImage}>Download for Translation”</button>
            <button className="btn back" onClick={resetForm}>Start a New</button>
          </div>
        </div>
      </div>
    </div>
  );
}  

export default SymptomReport;
