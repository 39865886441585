import React, { useEffect, useState } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaTimes } from 'react-icons/fa';
import './Navbar.css';
import logo from '../Pictures/logo.png';
import { GrLanguage } from "react-icons/gr";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);

      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          { pageLanguage: 'en' },
          'google_translate_element'
        );
      };
    };

    addGoogleTranslateScript();
  }, []);

  return (
    <div className="navbar sticky-top navbar-light bg-light">
      <div className="pink-bar">
        <GrLanguage size={20} />
        <div id="google_translate_element"></div>
      </div>

      <header className="navbar">
        <div className="navbar-container">
          <Link to="/" className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </Link>

          <button className="hamburger-icon" onClick={toggleMenu}>
            {isMenuOpen ? <FaTimes /> : <GiHamburgerMenu />}
          </button>

          <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
            <ul className="nav-links">
              <CustomLink to="/" dropdownItems={['How We Support You', 'Recommended Services']}>Home</CustomLink>
              <CustomLink to="/mediSupport-hub" dropdownItems={['Key Terms', 'Health Benefits', 'Medicare', 'Bulk Billing', 'Booking a GP', 'Emergency Contact']}>MediSupport Hub</CustomLink>
              <CustomLink to="/insight" dropdownItems={['Common Women Diseases', 'Australia Screening Programs']}>Trends</CustomLink>
              <CustomLink to="/symptom-checklist" dropdownItems={['Check Your Symptoms', 'Download Translated Report']}>Symptoms Checklist</CustomLink>
              <CustomLink to="/gp-locator" dropdownItems={['Suburbs Speaking Your Language', 'Locate Nearby GPs', 'Out-Of-Pocket Costs']}>Find A Doctor</CustomLink>
              <CustomLink to="/event" dropdownItems={['Ongoing Events in Melbourne']}>Event Planner</CustomLink>
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

function CustomLink({ to, children, dropdownItems, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
      {dropdownItems && (
        <ul className="dropdown">
          {dropdownItems.map((item, index) => (
            <li key={index} className="dropdown-item">
              {item}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
